import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

const GreenCheckbox = (props) => {
  const classes = useStyles();

  return (<Checkbox color="default" {...props} className={classes.root} />);
};

export default GreenCheckbox;
