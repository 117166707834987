import React, { Fragment } from 'react';
import {
  number,
  func,
  string,
  arrayOf,
  shape,
  bool,
} from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const renderHeaderCells = (tableConfig, orderBy, order, onRequestSort) => {
  const createSortHandler = property => (event) => {
    onRequestSort(event, property);
  };

  return tableConfig.map(({
    id,
    sortFieldPath,
    headerProps = {},
    label,
  }) => {
    const sortKey = sortFieldPath || id;
    const { tooltipPlacement, ...rest } = headerProps;

    if (!label) { return (
      <TableCell
        key={id}
        {...rest}
      />
    ); }

    return (
      <TableCell
        key={id}
        sortDirection={orderBy === sortKey ? order : false}
        {...rest}
      >
        <Tooltip
          title="Sort"
          placement={tooltipPlacement}
          enterDelay={300}
        >
          <TableSortLabel
            active={orderBy === sortKey}
            direction={order}
            onClick={createSortHandler(sortKey)}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  });
};

const renderContent = (
  hasCheckboxes,
  tableConfig,
  order,
  orderBy,
  onRequestSort,
  numSelected,
  rowCount,
  onSelectAllClick,
) => {
  if (!hasCheckboxes) return renderHeaderCells(tableConfig, orderBy, order, onRequestSort);

  return (
    <Fragment>
      <TableCell padding="checkbox" id="do-not-print">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={numSelected > 0}
          onChange={onSelectAllClick}
        />
      </TableCell>
      {renderHeaderCells(tableConfig, orderBy, order, onRequestSort)}
    </Fragment>
  );
};

const Head = ({
  hasCheckboxes,
  tableConfig,
  order,
  orderBy,
  onRequestSort,
  numSelected,
  rowCount,
  onSelectAllClick,
}) => (
  <TableHead>
    <TableRow>
      {renderContent(
        hasCheckboxes,
        tableConfig,
        order,
        orderBy,
        onRequestSort,
        numSelected,
        rowCount,
        onSelectAllClick,
      )}
    </TableRow>
  </TableHead>
);

Head.propTypes = {
  numSelected: number.isRequired,
  onRequestSort: func.isRequired,
  onSelectAllClick: func.isRequired,
  order: string.isRequired,
  orderBy: string.isRequired,
  rowCount: number.isRequired,
  tableConfig: arrayOf(shape({})).isRequired,
  hasCheckboxes: bool.isRequired,
};

export default Head;
