import React from 'react';
import filter from 'lodash/fp/filter';
import isFunction from 'lodash/isFunction';
import and from 'lodash/overEvery';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import measureHIA from 'assets/pdfs/measureHIA.pdf';
import installHIA from 'assets/pdfs/installHIA.pdf';

import { OLD_VENDOR_ADMIN_URL } from '../../../../constants';
import PdfLink from './PdfLink';

const isCorrectServiceType = serviceType => job => job.serviceType === serviceType;
const isInStatus = status => job => job.status === status;
const isChangeOrder = job => job.isChangeOrder;

const isMeasureJob = isCorrectServiceType('measurement');
const isInstallJob = isCorrectServiceType('installation');

const isCompleted = isInStatus('completed');

const getPDFUrl = template => job => (
  `${OLD_VENDOR_ADMIN_URL}/pdfdownload/${job.vendor.id}/${job.id}/${template}`
);

const staticPdfConfigs = [
  {
    label: 'Download Measure Home Improvement Agreement',
    uri: measureHIA,
    isDisplayed: isMeasureJob,
  },
  {
    label: 'Download Home Depot Window Treatment Measure Form and Installation Quote',
    uri: getPDFUrl('MeasureForm'),
    isDisplayed: and([isMeasureJob, isCompleted]),
  },
  {
    label: 'Download Install Home Improvement Agreement PDF',
    uri: installHIA,
    isDisplayed: isInstallJob,
  },
  {
    label: 'Download Home Services Installation Customer Approval PDF',
    uri: getPDFUrl('CustomerApproval'),
    isDisplayed: and([isInstallJob, isCompleted]),
  },
  {
    label: 'Download Change Order Home Improvement Form',
    uri: getPDFUrl('ChangeOrder'),
    isDisplayed: and([isInstallJob, isCompleted, isChangeOrder]),
  },
];

const StaticLinks = ({ job }) => flow(
  map(config => ({
    ...config,
    uri: isFunction(config.uri) ? config.uri(job) : config.uri,
  })),
  filter(config => config.isDisplayed(job)),
  map(config => <PdfLink {...config} />),
)(staticPdfConfigs);

export default StaticLinks;
