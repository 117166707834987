import React from 'react';
import {
  number,
  string,
  bool,
  object,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { getWholeAndFractionFromNumber } from './utilities';
import styles from './styles';

const Measurements = ({
  value,
  shortLabel,
  label,
  disabled,
  classes,
}) => {
  const containerClasses = classnames(
    classes.container,
    {
      [classes.disabled]: disabled,
    },
  );

  if (!value) {
    return (
      <div className={containerClasses}>
        <Typography variant="caption">{label}</Typography>
      </div>
    );
  }

  const { whole, fraction } = getWholeAndFractionFromNumber(value);
  const measurement = fraction ? `${whole} ${fraction}` : whole;

  return (
    <div className={containerClasses}>
      <Typography variant="caption" className={classes.bold}>
        {shortLabel}
      </Typography>
      <Typography variant="caption" className={classes.spacer}>
        {measurement}
      </Typography>
      <Typography variant="caption">&quot;</Typography>
    </div>
  );
};

Measurements.propTypes = {
  value: number.isRequired,
  shortLabel: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  classes: object.isRequired,
};

Measurements.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(Measurements);
