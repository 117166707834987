export default theme => ({
  categoryContainer: {
    padding: theme.spacing(1),
  },
  categoryPreface: {
    fontSize: '1rem',
  },
  category: {
    fontSize: '1rem',
    fontWeight: theme.text.boldWeight,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  column: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100px',
    alignItems: 'center',
    background: theme.palette.primary.lightGrey,
    border: theme.border,
    borderRadius: '4px',
    padding: '10px',
    marginRight: theme.spacing(2),
  },
  attributeLabel: {
    textAlign: 'center',
    lineHeight: '16px',
    flex: 1,
    justifyContent: 'flex-start',
  },
  attributeValue: {
    flex: 1,
    justifyContent: 'flex-end',
    textAlign: 'center',
    fontWeight: theme.text.boldWeight,
    fontSize: '3.1rem',
    padding: theme.spacing(1),
  },
  boldFonts: {
    fontWeight: theme.text.boldWeight,
  },
  roomAndWindowsContainer: {
    paddingTop: theme.spacing(2),
  },
  pdfWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  pdfLink: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
    color: theme.palette.primary.link,
    display: 'flex',
    alignItems: 'center',
  },
  pdfIcon: {
    marginLeft: theme.spacing(0.5),
  },
});
