const getFractionConfigItem = (value, rangeStart, rangeEnd) => ({
  value,
  rangeStart,
  rangeEnd,
});

const fractionsConfig = [
  getFractionConfigItem('1/8', 0.001, 0.125),
  getFractionConfigItem('1/4', 0.126, 0.25),
  getFractionConfigItem('3/8', 0.251, 0.375),
  getFractionConfigItem('1/2', 0.376, 0.5),
  getFractionConfigItem('5/8', 0.501, 0.625),
  getFractionConfigItem('3/4', 0.626, 0.75),
  getFractionConfigItem('7/8', 0.751, 0.875),
];

function betweenInclusive(value, rangeStart, rangeEnd) {
  return (rangeStart <= value) && (value <= rangeEnd);
}

function getFraction(decimals = 0) {
  return fractionsConfig
    .find(({ rangeStart, rangeEnd }) => betweenInclusive(decimals, rangeStart, rangeEnd))
    .value;
}

export default function getWholeAndFractionFromNumber(number) {
  const whole = Math.floor(number);
  const decimals = Number((number - whole).toFixed(3));
  const fraction = decimals ? getFraction(decimals) : 0;

  return {
    whole,
    fraction,
  };
}
