export default theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    flexWrap: 'wrap',
    flex: 1,
  },
  orderAndIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.up('xs')]: {
      flex: 'none',
    },
  },
  serviceType: {
    backgroundColor: '#4e08ca',
    color: '#fff',
    paddingLeft: '8px',
    paddingRight: '8px',
    lineHeight: '25px',
    fontWeight: '600',
    borderRadius: '3px 3px 3px 3px',
    marginLeft: 15,
  },
  grow: {
    flexGrow: 1,
  },
  headerText: {
    fontWeight: theme.text.boldWeight,
    paddingBottom: '0px',
    color: 'black',
  },
  downloadPDFText: {
    color: theme.palette.primary.link,
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
  pdfDialogCheckboxText: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfDialogButtonText: {
    textTransform: 'capitalize',
  },
  pdfDialogButtonBorder: {
    border: '3px solid',
    padding: '3px 15px',
    '&:hover': {
      backgroundColor: 'rgba(249, 99, 2, 0.2)',
      border: '3px solid',
      padding: '3px 15px',
    },
  },
});
