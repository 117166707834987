export default theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  loadingContainer: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
