import React from 'react';
import { string, any, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import startCase from 'lodash/startCase';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 5,
  },
  list: {
    'margin-left': theme.spacing(1),
  },
});

const LineItem = ({ display, quantity, cost, classes, overrideStyles }) => {
  const hasQuantity = quantity !== null && quantity !== undefined;
  const displayName = display === 'oversizevertical' ? 'oversizedwindowtreatment' : display;

  return (
    <div className={`${classes.wrapper} ${overrideStyles}`}>
      <div className={classes.list}>{startCase(displayName)} {hasQuantity && <span>x ({quantity})</span>} </div>
      <div>{cost}</div>
    </div>
  );
};

LineItem.propTypes = {
  display: string.isRequired,
  quantity: any,
  cost: any.isRequired,
  classes: object.isRequired,
  overrideStyles: string,
};

LineItem.defaultProps = {
  quantity: null,
  overrideStyles: '',
};

export default withStyles(styles)(LineItem);
