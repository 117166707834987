import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { func, string } from 'prop-types';

function ApiKeyDialog({ APIkey, cancelToggle }) {
  return (
    <>
      <DialogTitle>Generated API Key</DialogTitle>
      <DialogContent>
        <Typography variant="h6">API key:  {APIkey}</Typography>
        <DialogContentText>
          Please provide API key to Service Provider.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelToggle} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
}

ApiKeyDialog.propTypes = {
  APIkey: string.isRequired,
  cancelToggle: func.isRequired,

};

export default ApiKeyDialog;
