import React, { Fragment } from 'react';
import get from 'lodash/get';
import {
  string,
  object,
} from 'prop-types';
import startCase from 'lodash/startCase';
import CheckMark from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import homeDepotIcon from 'assets/images/home-depot-logo-sq.png';
import blindsComIcon from 'assets/images/blinds-com-logo.png';
import { addressType } from 'shared/types';
import Card from '../Card';
import styles from './styles';
import { SourceIcon } from '../../../shared/components';

const attributesConfig = [
  {
    label: 'Order Placed',
    renderAttribute: ({ order = {} }) => {
      const { date } = order;

      if (!date) return 'NA';

      return moment(date).format('MMM Do, YYYY');
    },
  },
  {
    label: 'THD PO',
    path: 'order.orderNumber',
  },
  {
    label: 'GCC PO',
    renderAttribute: ({ order = {} }) => {
      const { poNumbers = [] } = order;

      return poNumbers.map(poNumber => (
        <Typography key={poNumber} variant="body1">
          {poNumber}
        </Typography>
      ));
    },
  },
  {
    label: 'Store NO.',
    path: 'storeNumber',
  },
];

const getSiteIcon = (siteCode = '') => {
  const normalizedSiteCode = siteCode.toUpperCase();

  if (normalizedSiteCode === 'THD') return homeDepotIcon;

  if (normalizedSiteCode === 'BDC') return blindsComIcon;

  return null;
};

const renderSiteInfo = (siteCode = '', classes) => {
  const src = getSiteIcon(siteCode);

  return (
    <span style={{ marginRight: 16 }}>
      <img src={src} alt="site-source" className={classes.icon} />
    </span>
  );
};

const renderJobStatus = (status = '', classes) => {
  const isComplete = status.toLowerCase() === 'completed';

  if (!isComplete) return <Typography variant="body1">{startCase(status)}</Typography>;

  return (
    <div className={classes.completedStatusContainer}>
      <CheckMark className={classes.checkMark} />
      <Typography variant="body1" className={classes.completed}>Completed</Typography>
    </div>
  );
};

const renderAddress = (address = {}, classes) => (
  <div className={classes.attributeContainer}>
    <Typography className={classes.label}>Address</Typography>
    <Typography variant="body1">{address.line1}</Typography>
    <Typography variant="body1">{address.line2}</Typography>
    <Typography variant="body1">
      {address.city}, {address.state} {address.zipcode}
    </Typography>
  </div>
);

const renderAttributeContent = (path, renderAttribute, props, classes) => {
  if (renderAttribute) return renderAttribute(props, classes);

  const value = get(props, path);

  return <Typography variant="body1">{value}</Typography>;
};

const renderAttributes = (props, classes) => attributesConfig
  .map(({ label, path, renderAttribute }) => (
    <div key={label} className={classes.attributeContainer}>
      <Typography className={classes.label}>{label}</Typography>
      {renderAttributeContent(path, renderAttribute, props, classes)}
    </div>
  ));

const JobInformation = ({ siteCode, status, address, source, classes, ...rest }) => (
  <Card title="Job Information">
    <div className={classes.siteInfo}>
      {renderSiteInfo(siteCode, classes)} <SourceIcon source={source} showLabel />
    </div>
    <Fragment>
      <Typography className={classes.label}>Job Status</Typography>
      {renderJobStatus(status, classes)}
    </Fragment>
    {renderAddress(address, classes)}
    {renderAttributes(rest, classes)}
  </Card>
);

JobInformation.propTypes = {
  siteCode: string.isRequired,
  status: string.isRequired,
  address: addressType.isRequired,
  source: string.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(JobInformation);
