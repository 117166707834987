export default theme => ({
  container: {
    alignItems: 'baseline',
    flex: '0.8',
    display: 'flex',
    marginRight: '5px',
    maxWidth: '85px',
  },
  border: {
    borderBottom: '0.3px solid #232323',
  },
  disabled: {
    opacity: 0.3,
  },
  bold: {
    fontWeight: theme.text.boldWeight,
  },
  spacer: {
    marginLeft: '5px',
  },
});
