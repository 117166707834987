export default theme => ({
  addNoteButton: {
    border: 'none',
    backgroundColor: 'initial',
    color: theme.palette.primary.main,
  },
  icon: {
    height: '35px',
    width: '35px',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: '#999999',
  },
  dialogContainer: {
    minWidth: '34rem',
  },
  blankState: {
    paddingBottom: theme.spacing(1),
  },
  unreadChipWrapper: {
    marginLeft: 10,
    border: 1,
    borderRadius: 15,
    borderColor: theme.palette.primary.main,
  },
  unreadChip: {
    backgroundColor: 'rgba(249, 99, 2, 0.1)',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
});
