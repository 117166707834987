export default theme => ({
  confirmedTechniciansText: {
    fontSize: '18px',
    fontWeight: theme.text.boldWeight,
  },
  selectedChoice: {
    marginRight: 10,
  },
  selectedChoiceBorder: {
    border: `2px solid ${theme.palette.primary.link}`,
  },
  selectedChoicesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
});
