export default theme => ({
  filtersContainer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(6),
    },
  },
  filterSection: {
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  floatingLoadingContainer: {
    position: 'fixed',
    display: 'flex',
    top: '50%',
    left: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingCover: {
    position: 'fixed',
    zIndex: '2000',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff88',
  },
  container: {
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(1.25),
  },
  select: {
    width: '80%',
    marginLeft: '6px',
  },
  applyButton: {
    backgroundColor: '#C14C09',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '18.75px',
    textTransform: 'capitalize',
    fontWeight: '700',
    height: '36px',
    width: '100%',
    margin: '22px 22px 22px 0',
    '&:hover': {
      backgroundColor: '#FD751B',
    },
  },
});
