import React from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import identity from 'lodash/fp/identity';
import constant from 'lodash/fp/constant';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const jobAttributesConfig = [
  {
    label: 'Number of Windows',
    key: 'numberOfWindows',
  },

  {
    label: 'Highest Window (ft)',
    key: 'highestWindowHeight',
    renderValue: value => (value ? `${value}'` : 'NA'),
  },
  {
    label: 'House Year Built',
    key: 'yearBuilt',
    renderValue: value => (value ? `${value}` : 'NA'),
  },
  {
    label: 'Haul Away',
    key: 'windowHaul',
    isValid: value => value > 0,
  },
  {
    label: 'Removal',
    key: 'windowRemoval',
    isValid: value => value > 0,
  },
];

const JobAttribute = ({ label, key, jobValue, classes, renderValue }) => (
  <div key={key} className={classes.column}>
    <Typography variant="body2" className={classes.attributeLabel}>
      {label}
    </Typography>
    <Typography variant="h3" className={classes.attributeValue}>
      {renderValue(jobValue) || 'NA'}
    </Typography>
  </div>
);
JobAttribute.defaultProps = {
  renderValue: identity,
};
JobAttribute.propTypes = {
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  jobValue: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  renderValue: PropTypes.func,
};

const StyledJobAttribute = withStyles(styles)(JobAttribute);

const JobAttributesList = ({ attributes }) => flow(
  map(config => ({
    ...config,
    isValid: config.isValid || constant(true),
    jobValue: get(config.key)(attributes),
  })),
  filter(attr => attr.isValid(attr.jobValue)),
  map(attr => <StyledJobAttribute {...attr} />),
)(jobAttributesConfig);

JobAttributesList.propTypes = {
  jobAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withStyles(styles)(JobAttributesList);
