import React from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import classNames from 'classnames';
import {
  object,
  bool,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { jobType } from 'shared/types';

const styles = theme => ({
  text: {
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: theme.text.boldWeight,
  },
});

function CustomerNameCell({ job, isBold, classes }) {
  const firstName = get(job, 'customer.firstName', '');
  const lastName = get(job, 'customer.lastName', '');
  const fullName = `${capitalize(firstName)} ${capitalize(lastName)}`;

  const textStyles = classNames(
    { [classes.bold]: isBold },
  );

  return <div className={textStyles}>{fullName}</div>;
}

CustomerNameCell.propTypes = {
  job: jobType.isRequired,
  classes: object.isRequired,
  isBold: bool,
};

CustomerNameCell.defaultProps = {
  isBold: false,
};

export default withStyles(styles)(CustomerNameCell);
