import React, { Fragment, useState } from 'react';
import {
  object,
} from 'prop-types';
import { without, concat } from 'ramda/src';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '../OrderDetails/Card';
import styles from './styles';
import config from './config';

const HelpfulLinks = ({ classes }) => {
  const [expandedPanels, modifyExpandedPanels] = useState([]);

  const expandOrCollapsePanel = (panel) => {
    const panels = expandedPanels.includes(panel) ?
      without([panel], expandedPanels)
      : concat(expandedPanels, [panel]);

    return modifyExpandedPanels(panels);
  };

  const renderFiles = (files = []) => files.map(file => (
    <ExpansionPanelDetails className={classes.files}>
      <Typography>
        <a className={classes.downloadPDFLink} href={file.url} target="_blank" rel="noopener noreferrer">{file.title}</a>
      </Typography>
    </ExpansionPanelDetails>
  ));

  const renderFolders = assetFolders => assetFolders.map((folder) => {
    const { title, files, folders } = folder;
    return (
      <Fragment>
        <div className={classes.folder}>
          <Typography className={classes.headerText}>{title}</Typography>
          { folders ? renderFolders(folders) : renderFiles(files)}
        </div>
      </Fragment>
    );
  });

  const renderAssets = assets => (
    assets.map((asset) => {
      const { title, files, folders } = asset;
      return (
        <ExpansionPanel expanded={expandedPanels.includes(title)} onChange={() => expandOrCollapsePanel(title)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h6">{asset.title}</Typography>
            <Typography />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.rows}>
            { folders ? renderFolders(folders) : renderFiles(files)}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    })
  );
  return (
    <Card title="Helpful Links">
      {renderAssets(config)}
    </Card>
  );
};

HelpfulLinks.propTypes = { classes: object.isRequired };

export default withStyles(styles)(HelpfulLinks);
