import React from 'react';
import { PropTypes, object } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Error, Info, Warning, Check } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import { cx } from '../../utilities';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
  },
  successCard: {
    borderColor: theme.palette.primary.green,
  },
  infoCard: {
    borderColor: '#3e7697',
  },
  warningCard: {
    borderColor: '#FFC53D',
  },
  errorCard: {
    borderColor: theme.palette.primary.red,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '.1',
  },
  successBackground: {
    backgroundColor: theme.palette.primary.green,
  },
  infoBackground: {
    backgroundColor: '#3e7697',
  },
  warningBackground: {
    backgroundColor: '#FAAD14',
  },
  errorBackground: {
    backgroundColor: theme.palette.primary.red,
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    fontWeight: 'bold',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  successIcon: {
    color: theme.palette.primary.green,
  },
  infoIcon: {
    color: '#3e7697',
  },
  warningIcon: {
    color: '#faad14',
  },
  errorIcon: {
    color: theme.palette.primary.red,
  },
  message: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2),
  },
});

const icons = {
  success: Check,
  info: Info,
  warning: Warning,
  error: Error,
};

const Alert = ({ classes, message, severity, color, className }) => {
  if (!message) {
    return null;
  }

  function getColor() {
    return color || severity;
  }

  const severityBg = classes[getColor() + 'Background'] || '';
  const severityCard = classes[getColor() + 'Card'] || '';
  const severityIcon = classes[getColor() + 'Icon'] || '';
  const Icon = icons[severity];

  return (
    <div className={cx(classes.container, className)}>
      <Paper className={cx(classes.card, severityCard)} elevation={0}>
        <div className={cx(classes.background, severityBg)} />
        <div className={classes.content}>
          <Icon className={cx(classes.icon, severityIcon)} />
          {message}
        </div>
      </Paper>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  color: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  classes: object.isRequired,
  className: PropTypes.string,
};

Alert.defaultProps = {
  message: '',
  severity: 'info',
  color: '',
  className: '',
};

export default withStyles(styles)(Alert);
