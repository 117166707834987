export default theme => ({
  selectArrivalTimeFirst: {
    marginBottom: theme.spacing(1),
  },
  searchTextField: {
    marginBottom: theme.spacing(1),
  },
  selectedCountContainer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listBoxContainer: {
    border: '1px solid #ccc',
    borderRadius: 2,
    height: 350,
    overflow: 'auto',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    margin: 0,
  },
  listItemIcon: {
    minWidth: '1rem',
  },
  listItemText: {
    marginTop: '6px',
    marginBottom: '7px',
  },
  assignedTechnicians: {
    marginBottom: theme.spacing(2),
  },
  assignTechniciansButton: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
    textTransform: 'capitalize',
  },
  assignTechniciansButtonContainer: {
    maxWidth: '15rem',
  },
});
