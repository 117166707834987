export default theme => ({
  loadingContainer: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontWeight: theme.text.boldWeight,
    fontSize: theme.typography.pxToRem(15),
  },
  card: {
    flex: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  input: {
    padding: '10px',
    width: '400px',
  },
});
