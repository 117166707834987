import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

const NoMatch = ({ classes }) => (
  <div className={classes.container}>
    <Paper className={classes.card} elevation={2}>
      <div className={classes.iconContainer}>
        <ErrorIcon className={classes.icon} />
      </div>
      <Typography variant="body1" className={classes.message}>
        We could not find the page you were looking for.
      </Typography>
      <div className={classes.linkWrapper}>
        <Link to="/all-jobs" className={classes.link}>
          Go back to jobs
        </Link>
      </div>
    </Paper>
  </div>
);

NoMatch.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(NoMatch);
