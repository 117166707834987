export default theme => ({
  detailsContainer: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  attributeWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  photosAttributeWrapper: {
    display: 'flex',
  },
  label: {
    display: 'flex',
    marginRight: theme.spacing(1),
    fontWeight: theme.text.boldWeight,
    flex: 2,
    alignItems: 'center',
  },
  photoLabel: {
    marginRight: theme.spacing(1),
    fontWeight: theme.text.boldWeight,
  },
  value: {
    display: 'flex',
    flex: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
