export default theme => ({
  wrapper: {
    maxWidth: '15rem',
  },
  header: {
    fontSize: '18px',
    fontWeight: theme.text.boldWeight,
    marginBottom: theme.spacing(1),
  },
  circularSpinnerContainer: {
    height: '9rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularSpinnerText: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#000000',
    marginTop: '16px',
  },
  dateSelection: {
    marginTop: theme.spacing(1),
    padding: '5px 16px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '0.00938em',
    textAlign: 'center',
  },
  scheduledBorder: {
    border: `2px solid ${theme.palette.primary.link}`,
  },
  completedBorder: {
    border: `2px solid ${theme.palette.primary.green}`,
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  confirmText: {
    marginTop: theme.spacing(1),
  },
  confirmButton: {
    marginTop: theme.spacing(3),
  },
  preferredTimesButton: {
    marginTop: theme.spacing(1),
  },
  markAsDeliveredButton: {
    marginTop: theme.spacing(3),
    width: 'max-content',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.link}`,
  },
  icon: {
    marginRight: theme.spacing(1),
    height: '1.1rem',
    width: '1.1rem',
    color: theme.palette.primary.green,
  },
  toggleDialogButton: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.link,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  buttonContents: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  toggleDialogButtonText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  disabledButtonWrapper: {
    opacity: 0.5,
  },
  widthContainer: {
    maxWidth: '22rem',
  },
  contentText: {
    fontSize: '0.875rem',
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessageText: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#000000',
  },
});
