import React from 'react';
import classNames from 'classnames';
import {
  object,
  string,
  arrayOf,
  shape,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    flexDirection: 'column',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row',
      paddingLeft: theme.spacing(3),
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
  },
  title: {
    flex: '0 0 auto',
    marginRight: theme.spacing(1),
  },
});

function renderHeaderText(title) {
  return (
    <Typography variant="h4" id="tableTitle">
      {title}
    </Typography>
  );
}

function renderActions(actionsConfig) {
  return actionsConfig.map(({ title, onClick, materialUIIcon }) => (
    <Tooltip key={title} title={title} className="do-not-print">
      <IconButton aria-label={title} onClick={onClick}>
        {materialUIIcon}
      </IconButton>
    </Tooltip>
  ));
}

function TableToolbar({ classes, title, actionsConfig }) {
  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.title}>
        {renderHeaderText(title)}
      </div>
      <div className={classes.actions}>
        {renderActions(actionsConfig)}
      </div>
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  classes: object.isRequired,
  title: string.isRequired,
  actionsConfig: arrayOf(shape({})),
};

TableToolbar.defaultProps = {
  actionsConfig: [],
};

export default withStyles(styles)(TableToolbar);
