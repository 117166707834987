import React from 'react';
import { object } from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

function Dashboard({ classes, left, right }) {
  const sideBarClasses = `${classes.sidebar} do-not-print`;

  return (
    <div className={classes.container}>
      <Paper className={sideBarClasses} elevation={2}>
        {left}
      </Paper>
      <div className={classes.mainContent}>
        {right}
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  classes: object.isRequired,
  left: object.isRequired,
  right: object.isRequired,
};

Dashboard.defaultProps = {
};

export default withStyles(styles)(Dashboard);
