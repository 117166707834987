import React from 'react';
import { string } from 'prop-types';
import MySnackbarContent from './MySnackbarContent';


class CustomizedSnackbars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  render() {
    const { message } = this.props;

    return (
      <div>
        { this.state.open &&
          (
          <MySnackbarContent
            variant="error"
            message={message}
            onClose={() => { this.setState({ open: false }); }}
          />
          )
        }
      </div>
    );
  }
}

CustomizedSnackbars.propTypes = {
  message: string.isRequired,
};

export default CustomizedSnackbars;
