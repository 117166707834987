import {
  shape,
  string,
  arrayOf,
  instanceOf,
  object,
  number,
  bool,
  oneOfType,
  oneOf,
} from 'prop-types';

export const appointmentType = shape({
  id: string.isRequired,
  dateCreated: string.isRequired,
  dateEnd: string,
  dateStart: string,
  mvNumber: string.isRequired,
  status: string.isRequired,
  technicians: arrayOf(string).isRequired,
});

export const addressType = shape({
  id: string,
  line1: string.isRequired,
  line2: string,
  city: string.isRequired,
  state: string.isRequired,
  zipcode: string.isRequired,
});

export const timeslotType = shape({
  dateEnd: oneOfType([
    instanceOf(Date),
    string,
  ]).isRequired,
  dateStart: oneOfType([
    instanceOf(Date),
    string,
  ]).isRequired,
});

export const customerType = shape({
  id: string,
  email: string,
  firstName: string.isRequired,
  lastName: string.isRequired,
  phones: arrayOf(
    shape({
      number: string.isRequired,
      canCall: bool,
      canText: bool,
    }),
  ),
});

export const noteType = shape({
  id: string.isRequired,
  author: string.isRequired,
  authorRole: oneOf(['Admin', 'technician', 'Cec', 'Customer', 'InStore']),
  content: string.isRequired,
  date: string.isRequired,
});

export const flagNoteType = shape({
  author: string.isRequired,
  authorRole: oneOf(['Admin', 'technician', 'Cec', 'Customer']).isRequired,
  date: string.isRequired,
  measurements: shape({
    width: string,
    height: string,
    depth: string,
  }),
  note: string,
  reason: string.isRequired,
});

export const displayAttributeType = shape({
  attributeKey: string.isRequired,
  value: string.isRequired,
  displayName: string,
});

export const productInformationType = shape({
  configurationId: string.isRequired,
  productId: number.isRequired,
  omsId: number,
  productName: string.isRequired,
  poNumber: string,
  productSpecifications: string,
  mountingRequirements: string,
  productPrice: number,
  productSalesTax: number,
  configurationDetails: arrayOf(
    shape({
      displayName: string.isRequired,
      choices: arrayOf(string),
    }),
  ),
  isDelivered: bool,
});

export const itemType = shape({
  id: string.isRequired,
  attributes: object,
  displayAttributes: arrayOf(displayAttributeType),
  initialAttributes: object,
  isInstalled: bool,
  items: arrayOf(string),
  label: string,
  name: string,
  notes: arrayOf(noteType),
  flagNote: flagNoteType,
  parentId: string,
  photos: arrayOf(shape({
    id: string.isRequired,
    url: string.isRequired,
    createdDate: string,
  })),
  productInformation: productInformationType,
  type: string,
});

const chargeType = shape({
  comment: string,
  cost: number.isRequired,
  displayName: string,
  retail: number,
  specId: string.isRequired,
  specType: string.isRequired,
  value: oneOfType([
    string,
    number,
  ]),
});

const orderType = shape({
  date: string.isRequired,
  orderNumber: string.isRequired,
  poNumbers: arrayOf(string),
});

export const pricingResultType = shape({
  id: string,
  charges: arrayOf(chargeType),
  cost: number,
  retail: number,
  salesTax: number,
});

const vendorType = shape({
  id: string,
  email: string,
  mvNumber: string.isRequired,
  name: string.isRequired,
  phoneNumber: string,
});

export const relatedJobType = shape({
  id: string.isRequired,
  attributes: object,
  isReworkOf: string,
  order: orderType,
  serviceType: string,
  status: string.isRequired,
  vendor: vendorType,
});

const signatureType = shape({
  id: string.isRequired,
  date: string.isRequired,
  signerName: string.isRequired,
  signerRole: string.isRequired,
  url: string.isRequired,
});

export const statusHistoryType = arrayOf(
  shape({
    status: string.isRequired,
    date: string.isRequired,
    updatedBy: string,
  }),
);

export const jobType = shape({
  id: string.isRequired,
  address: addressType,
  appointments: arrayOf(appointmentType),
  attributes: object,
  availableTechnicians: arrayOf(string),
  availableTimeslots: arrayOf(timeslotType),
  changeOrder: shape({
    id: string.isRequired,
    charges: arrayOf(chargeType),
    isAgreedTo: bool,
    leadNumber: string,
    newCompletionDate: string,
    serviceProviderLicense: string,
    technician: string,
    customerSignature: string,
    technicianSignature: string,
    orderNumber: string,
    poNumber: string,
  }),
  customer: customerType,
  initialAttributes: object,
  initialPricingResult: pricingResultType,
  items: arrayOf(itemType),
  isReworkOf: string,
  migrateFromSql: bool,
  notes: arrayOf(noteType),
  order: orderType,
  parentJobId: string,
  preferredAppointmentTimes: arrayOf(timeslotType),
  pricingAgreementId: string,
  pricingResult: pricingResultType,
  productLine: string,
  relatedJobs: relatedJobType,
  serviceType: string.isRequired,
  signature: signatureType,
  siteCode: string,
  status: string,
  statusHistory: statusHistoryType,
  storeNumber: string,
  technicians: arrayOf(string),
  vendor: vendorType,
  hasJobBeenRead: bool,
});
