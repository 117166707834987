import React from 'react';
import get from 'lodash/get';
import {
  string,
  any,
} from 'prop-types';

function ApiKey({ list, field }) {
  if (field === 'mvNumbers') {
    return list.map(mvNumber => (<div key={mvNumber}>{mvNumber}</div>));
  }
  const apiKey = get(list, field);

  return <div>{apiKey}</div>;
}

ApiKey.propTypes = {
  list: any.isRequired,
  field: string.isRequired,
};

export default ApiKey;
