import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import compose from 'lodash/flowRight';
import { gql } from '@apollo/client';
import { Redirect, useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import get from 'lodash/get';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { Loader } from '../../shared/components';
import Snackbars from './Snackbars';
import { TOKEN, AUTH_TOKEN, PERSISTENT_USER, SUPER_ADMIN, MANDIDEV } from '../../constants';
import { decodeToken } from '../../shared/utilities';
import styles from './styles';


function SignIn({ classes }) {
  const history = useHistory();
  if (TOKEN()) return <Redirect to="/" />;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const LOGIN_MUTATION = gql`
    mutation ($spaLoginInput: SPALoginInput!) {
      spaLogin(spaLoginInput: $spaLoginInput)
    }
  `;

  const login = (token) => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN, token.spaLogin);
      const currentUserRoles = decodeToken(token.spaLogin).roles;
      const isSuperAdmin = get(currentUserRoles, SUPER_ADMIN);
      const isMandiDev = get(currentUserRoles, MANDIDEV);
      localStorage.setItem('isSuperAdmin', isSuperAdmin);
      localStorage.setItem('isMandiDev', isMandiDev);
      history.push('/');
    }
  };

  const persistUserName = (usernameToPersist) => {
    localStorage.setItem(PERSISTENT_USER, usernameToPersist.toUpperCase());
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Mutation
          mutation={LOGIN_MUTATION}
          onCompleted={data => login(data)}
        >
          {(spaLogin, { loading, error }) => (
            <div>
              {error && (
                <Snackbars
                  variant="error"
                  className={classes.margin}
                  message="Invalid login attempt."
                />
              )}
              <form
                className={classes.form}
                onSubmit={(event) => {
                  event.preventDefault();
                  spaLogin({
                    variables: {
                      spaLoginInput: {
                        username,
                        password,
                      },
                    },
                  });
                  persistUserName(username);
                }}
              >
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Username</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                  />
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign in
                </Button>
              </form>
              <div className={classes.loaderContainer}>
                {loading && <Loader />}
              </div>
            </div>
          )}
        </Mutation>
      </Paper>
    </main>
  );
}

SignIn.propTypes = {
  classes: object.isRequired,
};

const EnhancedLogin = compose(
  withStyles(styles),
)(SignIn);

export default EnhancedLogin;
