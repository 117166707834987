export default theme => ({
  attributeContainer: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: 'black',
    fontSize: '18px',
    fontWeight: theme.text.boldWeight,
  },
  completedStatusContainer: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
  },
  checkMark: {
    color: theme.palette.primary.green,
    marginRight: theme.spacing(0.5),
  },
  completed: {
    color: theme.palette.primary.green,
  },
  icon: {
    height: '40px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.link,
    textDecoration: 'none',
  },
  siteInfo: {
    display: 'flex',
    alignItems: 'center',
  },
});
