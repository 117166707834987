import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {
  object,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  Table,
  ApiKey,
} from '../../shared/components';
import Card from '../OrderDetails/Card';
import ApiKeyDialog from './ApiKeyDialog';
import CreatingApiKeyDialog from './CreatingApiKeyDialog';
import { LOGIN_USER, GET_AUTH_TOKEN, API_URL } from '../../constants';

const styles = theme => ({
  container: {
    display: 'flex',
    width: '25%',
    paddingLeft: '20px',
  },
  list: {
    width: '50%',
    paddingLeft: '20px',
  },
  containerTable: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
});

const initialState = {
  mvNumbers: [],
  email: '',
  createdBy: '',
  APIkey: '',
};

const title = 'List of API keys';
const tableConfig = [
  {
    id: 'id',
    label: 'API Key',
    sortFieldPath: 'id',
    renderValue: list => <ApiKey list={list} field="id" />,
  },
  {
    id: 'createdBy',
    label: 'createdBy',
    sortFieldPath: 'createdBy',
    renderValue: list => <ApiKey list={list} field="createdBy" />,
  },
  {
    id: 'email',
    label: 'email',
    sortFieldPath: 'email',
    renderValue: list => <ApiKey list={list} field="email" />,
  },
  {
    id: 'mvNumbers',
    label: 'mvNumbers',
    sortFieldPath: 'mvNumbers',
    renderValue: list => <ApiKey list={list.mvNumbers} field="mvNumbers" />,
  },
];

const DialogSelect = ({ classes }) => {
  const [ApiKeys, setApiKeys] = useState([]);

  function getAllAPiKeys() {
    axios.get(`${API_URL}/auth/getAllApiKeys`, {
      headers: {
        authorization: `Bearer ${GET_AUTH_TOKEN()}`,
      },
    }).then((res) => {
      setApiKeys(res.data);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error: ', error);
    });
  }

  useEffect(() => getAllAPiKeys(), []);
  const [state, setState] = useState({
    ...initialState,
  });

  const [isDialogOpen, setDialog] = useState(false);
  const toggleDialog = () => setDialog(!isDialogOpen);

  const handleChange = name => (event) => {
    const value = name === 'mvNumbers' ? event.target.value.trim().split(',') : event.target.value;

    setState({ ...state, [name]: value });
  };

  function cancelToggle() {
    setState({ ...initialState });
    toggleDialog();
  }


  function createApiKey() {
    const { email, mvNumbers } = state;
    const payload = { email, mvNumbers, createdBy: LOGIN_USER() };
    axios.post(`${API_URL}/auth/generateApiKey`, payload, {
      headers: {
        authorization: `Bearer ${GET_AUTH_TOKEN()}`,
      },
    }).then((res) => {
      const newKey = { ...payload, id: res.data };

      setState({ ...state, APIkey: res.data });
      setApiKeys([...ApiKeys, newKey]);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error: ', error);
    });
  }

  return (
    <div>
      <div className={classes.container}>
        <Card title="API Key">
          <Button onClick={toggleDialog} color="primary" variant="contained">Create new key</Button>
          <Dialog disableBackdropClick disableEscapeKeyDown open={isDialogOpen} onClose={toggleDialog} fullWidth>
            {state.APIkey ? (
              <ApiKeyDialog APIkey={state.APIkey} cancelToggle={cancelToggle} />
            ) : (
              <CreatingApiKeyDialog email={state.email} mvNumbers={state.mvNumbers} handleChange={handleChange} cancelToggle={cancelToggle} createApiKey={createApiKey} />
            )}
          </Dialog>
        </Card>
      </div>
      <div className={classes.containerTable}>
        <Table
          title={title}
          tableConfig={tableConfig}
          data={ApiKeys}
          rowsShown={10}
          pagination
        />
      </div>
    </div>
  );
};

DialogSelect.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(DialogSelect);
