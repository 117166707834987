export default theme => ({
  windowIcon: {
    flex: 1,
  },
  windowDetailsContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingRight: 12,
    flexBasis: 90,
    display: 'flex',
  },
  windowIconContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
    paddingRight: 15,
    display: 'flex',
  },
  windowIconText: {
    marginLeft: 7,
    height: 19,
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#4a4a4a',
  },
  carrotUp: { width: 9.3, height: 5.4 },
  carrotDown: { width: 9.3, height: 5.4, transform: [{ rotate: '180deg' }] },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  border: {
    borderTop: '1px solid',
    borderColor: '#ccc',
  },
  circle: {
    width: 21,
    height: 21,
    borderRadius: 21 / 2,
    backgroundColor: '#F96302',
    margin: 5,
  },
  circleLabel: {
    // width: 10,
    height: 17,
    fontSize: 14,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    alignSelf: 'center',
    // lineHeight: 19,
  },
  nameContainer: {
    flex: 4,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
  },
  name: {
    fontSize: 18,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#000000',
  },
});
