export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '91vh',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  sidebar: {
    flex: 1,
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      margin: 0,
    },
  },
  mainContent: {
    position: 'relative',
    flex: 5,
    display: 'flex',
    width: '80%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
});
