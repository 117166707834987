import React from 'react';
import {
  PropTypes,
  oneOfType,
  string,
  object,
  bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.red,
  },
  message: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2),
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: theme.palette.primary.link,
    textDecoration: 'none',
  },
});

const Error = ({ error, custom = false, classes, message }) => {
  // eslint-disable-next-line no-console
  console.log(error); // use new relic or other logging service to log error

  return (
    <div className={classes.container}>
      <Paper className={classes.card} elevation={2}>
        <div className={classes.iconContainer}>
          <ErrorIcon className={classes.icon} />
        </div>
        {custom ? (
          <Typography variant="body1" className={classes.message}>
            Oops, please make sure to refresh the page and try again
          </Typography>
        ) : (
          <>
            <Typography variant="body1" className={classes.message}>
              { message.length > 0 ? message : 'Either access was denied, this item does not exist, or something happened on our end.' }
            </Typography>
            <div className={classes.linkWrapper}>
              <Link to="/all-jobs" className={classes.link}>
                Go back to jobs
              </Link>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

Error.propTypes = {
  error: oneOfType([
    string,
    object,
  ]).isRequired,
  classes: object.isRequired,
  custom: bool.isRequired,
  message: PropTypes.string,
};

Error.defaultProps = {
  message: '',
};

export default withStyles(styles)(Error);
