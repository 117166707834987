const LABOR_ONLY_PO = 'labor only po';

const checkIfJobIsLaborOnlyPo = (notes) => {
  let result = false;
  if (notes.length > 0)
  {
    notes.forEach((note) => {
      if (note.author.toLowerCase() === LABOR_ONLY_PO)
      {
        result = true;
      }
    });
  }
  return result;
};

export default checkIfJobIsLaborOnlyPo;
