import React, { useState, Fragment } from 'react';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import classnames from 'classnames';
import {
  object,
  bool,
  string,
  arrayOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import UpArrow from '@material-ui/icons/KeyboardArrowUp';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';

import {
  flagNoteType,
  noteType,
  productInformationType,
  displayAttributeType,
} from 'shared/types';
import Measurements from './Measurements';
import Details from './Details';
import styles from './styles';

const measurementConfig = [
  {
    key: 'width',
    shortLabel: 'W:',
  },
  {
    key: 'height',
    shortLabel: 'H:',
  },
  {
    key: 'depth',
    shortLabel: 'D:',
  },
];

function getFlagType(
  flagNote,
  attributes,
  notes = [],
) {
  const { specialtyShape, isCutout } = attributes;

  if (flagNote || specialtyShape || isCutout) return 'redFlag';

  if (notes.length) return 'yellowFlag';


  return '';
}

function getMeasurementsToShow(isOutsideMount) {
  if (isOutsideMount) return measurementConfig.filter(({ key }) => key !== 'depth');

  return measurementConfig;
}

function renderMeasurements(attributes) {
  const mountType = attributes.mountType || '';
  const isOutsideMount = mountType.toLowerCase() === 'outside';
  const measurementsToShow = getMeasurementsToShow(isOutsideMount);

  return measurementsToShow.map(({ key, shortLabel }) => (
    <Measurements
      key={key}
      label={capitalize(key)}
      shortLabel={shortLabel}
      value={attributes[key]}
      readOnly
    />
  ));
}

const renderShowDetailsButton = (showDetails, toggleShowDetails) => (
  <IconButton onClick={toggleShowDetails}>
    {showDetails ? <UpArrow /> : <DownArrow />}
  </IconButton>
);

const WindowSummary = ({
  flagNote,
  attributes = {},
  isFirst,
  label,
  notes,
  productInformation,
  displayAttributes,
  classes,
  ...rest
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const toggleShowDetails = () => setShowDetails(!showDetails);

  const flagType = getFlagType(flagNote, attributes, notes);
  const flagClass = classes[flagType];

  const containerClasses = classnames(
    classes.container,
    {
      [classes.border]: !isFirst,
      [flagClass]: Boolean(flagType),
    },
  );

  return (
    <Fragment>
      <div className={containerClasses}>
        <div className={classes.circleWrapper}>
          <div className={classes.circle}>
            <Typography className={classes.circleText}>
              {label}
            </Typography>
          </div>
        </div>
        <div className={classes.measurementsWrapper}>
          {renderMeasurements(attributes)}
        </div>
        <div className={classes.buttonWrapper}>
          {renderShowDetailsButton(showDetails, toggleShowDetails, classes)}
        </div>
      </div>
      <Collapse in={showDetails}>
        <Details
          flagNote={flagNote}
          attributes={attributes}
          note={get(notes, '0', {})}
          productInformation={productInformation}
          displayAttributes={displayAttributes}
          {...rest}
        />
      </Collapse>
    </Fragment>
  );
};

WindowSummary.propTypes = {
  flagNote: flagNoteType,
  attributes: object,
  isFirst: bool.isRequired,
  label: string.isRequired,
  notes: arrayOf(noteType),
  productInformation: productInformationType,
  displayAttributes: arrayOf(displayAttributeType),
  classes: object.isRequired,
};

WindowSummary.defaultProps = {
  flagNote: undefined,
  notes: [],
  attributes: {},
  displayAttributes: [],
  productInformation: {},
};

export default withStyles(styles)(WindowSummary);
