import React from 'react';
import { Link } from 'react-router-dom';
import {
  object,
} from 'prop-types';
import ChevronRight from '@material-ui/icons/ChevronRight';
import withStyles from '@material-ui/core/styles/withStyles';
import { jobType } from 'shared/types';
import { useMutation } from '@apollo/client';
import { SET_JOB_HAS_BEEN_READ } from 'apollo/mutations';
import { mutationArgWrapper } from 'shared/utilities';

const styles = theme => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  viewDetailsLink: {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: theme.text.boldWeight,
  },
});

const ViewDetailsCell = ({ job, classes }) => {
  const jobDetailsUrl = `/job/${job.id}`;
  const [setJobHasBeenRead] = useMutation(SET_JOB_HAS_BEEN_READ, {
    errorPolicy: 'none',
  });
  const setJobHasBeenReadWrappedMutation = mutationArgWrapper('setJobHasBeenRead', setJobHasBeenRead);
  return (
    <Link
      to={jobDetailsUrl}
      className={classes.viewDetailsLink}
      onClick={() => setJobHasBeenReadWrappedMutation({ status: true, jobIds: [job.id] })}
    >
      View Details
      <span className={classes.icon}>
        <ChevronRight />
      </span>
    </Link>
  );
};

ViewDetailsCell.propTypes = {
  job: jobType.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(ViewDetailsCell);
