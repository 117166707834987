import React from 'react';
import {
  bool,
  string,
} from 'prop-types';

import StoreIcon from '@material-ui/icons/StorefrontOutlined';
import OnlineIcon from '@material-ui/icons/DesktopWindowsOutlined';
import { makeStyles } from '@material-ui/core';

const defaultColor = '#7A7978';
const useStyles = makeStyles({
  root: {
    color: defaultColor,
    display: 'flex',
    '& svg': {
      marginRight: 5,
    },
  },
});

function getFormattedSource(source) {
  return (source || '').toLocaleLowerCase();
}

function getLabel(source) {
  if (getFormattedSource(source) === 'online') {
    return 'Online';
  }

  return 'In-Store';
}


export const JobSourceIcon = ({ source, showLabel, ...rest }) => {
  const classes = useStyles();
  let Icon = StoreIcon;
  if (getFormattedSource(source) === 'online') {
    Icon = OnlineIcon;
  }

  return (
    <span className={classes.root}>
      <Icon {...rest} />
      {showLabel && <span>{getLabel(source)}</span>}
    </span>
  );
};

JobSourceIcon.propTypes = {
  source: string.isRequired,
  showLabel: bool,
};

JobSourceIcon.defaultProps = {
  showLabel: false,
};

export default JobSourceIcon;
