import React, { useState } from 'react';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  DateCell,
  UnscheduledOrderNumberCell,
  ServiceTypeCell,
  CustomerNameCell,
  AddressCell,
  PrimaryPhoneNoCell,
  ViewDetailsCell,
  SourceCell,
  Loader,
  Error,
  PaginationTable,
} from '../../shared/components';
import styles from './styles';

const useStyles = makeStyles(styles);

const GET_JOBS_WITH_UNREAD_NOTE = gql`
  query searchJobs($pageSize: Int!, $pageNumber: Int!, $sortField: String!, $sortDirection: String!, $jobStatuses: [String], $sources: [String]) {
    searchJobs(
      pageSize: $pageSize,
      pageNumber: $pageNumber,
      sortField: $sortField,
      sortDirection: $sortDirection,
      includeFacets: false,
      jobStatuses: $jobStatuses,
      onlyJobsWithUnreadNotes: true,
      sources: $sources,
    ) {
    jobs {
        id
        customer {
          firstName
          lastName
          phones {
            number
          }
        }
        address {
          line1
          line2
          city
          state
          zipcode
        }
        aggregatedAttributes {
          oversizedVerticalFeet
        }
        order {
          orderNumber
          date
        }
        serviceType
        siteCode
        hasJobBeenRead
        source
      }
      paginationMetadata {
        currentPage
        pageSize
        totalPageCount
        totalItemCount
      }
    }
  }
`;

const tableConfig = [
  {
    id: 'date',
    columnName: 'Date',
    sortField: 'date',
    renderValue: job => <DateCell job={job} />,
  },
  {
    id: 'orderNumber',
    columnName: 'Order',
    sortField: 'orderNumber',
    renderValue: job => <UnscheduledOrderNumberCell job={job} isBold={false} />,
  },
  {
    id: 'serviceType',
    columnName: 'Type',
    sortField: 'serviceType',
    renderValue: job => <ServiceTypeCell job={job} />,
  },
  {
    id: 'source',
    columnName: 'Source',
    sortField: 'source',
    renderValue: job => <SourceCell job={job} />,
  },
  {
    id: 'name',
    columnName: 'Name',
    sortField: 'name',
    renderValue: job => <CustomerNameCell job={job} />,
  },
  {
    id: 'location',
    columnName: 'Location',
    sortField: 'location',
    renderValue: job => <AddressCell job={job} />,
  },
  {
    id: 'phones',
    columnName: 'Primary No.',
    sortField: 'phones',
    renderValue: job => <PrimaryPhoneNoCell job={job} />,
  },
  {
    id: 'viewOrderDetails',
    columnName: '',
    renderValue: job => <ViewDetailsCell job={job} />,
  },
];

const jobStatuses = [
  'finalized-in-app',
  'in-progress',
  'pending-products',
  'pending-schedule',
  'pending-assignment',
  'pending-change-order-payment',
  'scheduled',
  'changed',
];

function JobsWithUnreadNote() {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(50);
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const { loading, error, data, fetchMore, networkStatus } = useQuery(GET_JOBS_WITH_UNREAD_NOTE, {
    variables: {
      pageSize: pageSize,
      pageNumber: 1,
      sortField: sortField,
      sortDirection: sortDirection,
      jobStatuses: jobStatuses,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingMoreJobs = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMoreJobs && !data) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (error) return <Error error={error} />;

  const {
    jobs,
    paginationMetadata,
  } = data.searchJobs;

  return (
    <div className={classes.container}>
      <PaginationTable
        title="Unread Notes"
        tableConfig={tableConfig}
        data={jobs}
        currentPage={paginationMetadata.currentPage}
        pageSize={pageSize}
        totalItemCount={paginationMetadata.totalItemCount}
        onChangePage={(page) => {
          fetchMore({
            variables: {
              pageNumber: page,
            },
          });
        }}
        sortField={sortField}
        sortDirection={sortDirection}
        onChangeSortDirection={setSortDirection}
        onChangeSortField={setSortField}
        onChangePageSize={setPageSize}
        isLoading={loadingMoreJobs || loading}
      />
    </div>
  );
}

export default JobsWithUnreadNote;
