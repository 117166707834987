/* eslint-disable radix */
import isString from 'lodash/fp/isString';

const DateConstructor = Date;
const ISOWithoutTimezoneRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?$/;

const parseLocalTime = (isoString) => {
  const match = isoString.match(ISOWithoutTimezoneRegex);

  return new DateConstructor(
    parseInt(match[1]), // year
    parseInt(match[2]) - 1, // month
    parseInt(match[3]), // day
    parseInt(match[4]), // hour
    parseInt(match[5]), // minute
    parseInt(match[6]), // second
  );
};

const testString = '1970-01-01T00:00:00';

if (new Date(testString).toISOString() !== parseLocalTime(testString).toISOString()) {
  // eslint-disable-next-line no-console
  console.warn(`Detected Safari date string without without timezone bug. Safari treats this
as a UTC time, while all other browsers follow the spec and treat it as a
local time. Intercepting constructor call to return local time instead. See
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#Date_Time_String_Format
for more information.`);

  // eslint-disable-next-line no-global-assign, no-native-reassign
  Date = function Date(...args) {
    const date = new DateConstructor(...args);

    if (args && args.length === 1 && isString(args[0]) && ISOWithoutTimezoneRegex.test(args[0])) {
      return parseLocalTime(args[0]);
    }

    return date;
  };
  Date.parseLocalTime = parseLocalTime;
  Object.defineProperties(Date, Object.getOwnPropertyDescriptors(DateConstructor));
}
