export default (it) => {
  const split = it.split(/[^0-9]+/);
  return new Date(
    split[0],
    // eslint-disable-next-line radix
    parseInt(split[1]) - 1,
    split[2],
    split[3],
    split[4],
    split[5],
  );
};
