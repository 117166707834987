import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {
  object,
  bool,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { jobType } from 'shared/types';

const styles = theme => ({
  text: {
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: theme.text.boldWeight,
  },
});

function AddressCell({ job: { address = {} } = {}, isBold, classes }) {
  if (isEmpty(address)) return null;

  const {
    city = '',
    line1 = '',
    line2 = '',
    state = '',
    zipcode = '',
  } = address;
  const textStyles = classNames(
    classes.text,
    { [classes.bold]: isBold },
  );

  return (
    <div className={textStyles}>
      <div>
        {line1} {line2}
      </div>
      <div>
        {city}, {state} {zipcode}
      </div>
    </div>
  );
}

AddressCell.propTypes = {
  job: jobType.isRequired,
  classes: object.isRequired,
  isBold: bool,
};

AddressCell.defaultProps = {
  isBold: false,
};

export default withStyles(styles)(AddressCell);
