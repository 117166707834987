import React, { useState, useMemo } from 'react';
import ReactJson from 'react-json-view';
import { object, func } from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import join from 'lodash/fp/join';
import get from 'lodash/fp/get';
import flow from 'lodash/fp/flow';
import startsWith from 'lodash/fp/startsWith';
import cloneDeep from 'lodash/fp/cloneDeep';
import reduce from 'lodash/reduce';
import curry from 'lodash/curry';
import unset from 'lodash/unset';
import endsWith from 'lodash/fp/endsWith';
import flattenObj from 'flat';
import { Loader, Error } from '../../shared/components';
import { UPDATE_JOB_EDITOR, JOB_STATUS } from '../../apollo/mutations';
import { GET_JOB_BY_ID_JOB_EDITOR } from '../../apollo/queries';
import styles from './styles';
import { LOGIN_USER } from '../../constants';

const removeTypenameFields = obj => curry(reduce)(
  flattenObj(obj),
  (m, v, k) => ((endsWith('__typename')(k) && unset(m, k)) || true) && m,
  obj,
);
const namespaceTest = expected => namespace => flow(
  join('.'),
  startsWith(expected),
)(namespace);
const updateJobMutation = (mutation, key) => ({ updated_src: updatedSrc }) => {
  const data = flow(
    cloneDeep,
    removeTypenameFields,
  )(get(key)(updatedSrc));

  return mutation({
    variables: {
      updateJobByDevInput: {
        jobId: updatedSrc.id,
        [key]: data,
      },
    },
  });
};

const updateJobStatusMutation = mutation => ({ updated_src: updatedSrc }) => {
  const data = get('status')(updatedSrc);

  return mutation({
    variables: {
      setJobStatusInput: {
        jobId: updatedSrc.id,
        status: data,
        updatedBy: LOGIN_USER(),
      },
    },
  });
};
const buildEditableTopLevelElementArray = mutation => key => [namespaceTest(key), updateJobMutation(mutation, key)];
const useEditableRegions = ({ updateJobEditor, setStatus }) => {
  const buildEditableTopLevelElement = useMemo(() => buildEditableTopLevelElementArray(updateJobEditor), []);
  const editMap = useMemo(() => new Map([
    buildEditableTopLevelElement('address'),
    buildEditableTopLevelElement('customer'),
    buildEditableTopLevelElement('vendor'),
    [namespaceTest('status'), updateJobStatusMutation(setStatus)],
  ]), [buildEditableTopLevelElement]);

  const edit = (obj) => {
    let edited = false;

    editMap.forEach((callback, test) => {
      if (test([...obj.namespace, obj.name])) {
        callback(obj);
        edited = true;
      }
    });
    return edited;
  };

  return [
    edit,
  ];
};

function jobEditor({ classes, updateJobEditor, setStatus }) {
  const [jobId, setJobId] = useState('');
  const [onEdit] = useEditableRegions({ updateJobEditor, setStatus });

  return (
    <div>
      <div className={classes.center}>
        <Typography>please enter Job ID: </Typography>
        <input className={classes.input} type="text" name="jobId" onChange={e => setJobId(e.target.value)} />
      </div>
      {jobId && (
        <Query
          query={GET_JOB_BY_ID_JOB_EDITOR}
          variables={{ id: jobId }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <div className={classes.loadingContainer}>
                  <Loader />
                </div>
              );
            }

            if (error) return <Error error={error} />;
            return (
              <Paper className={classes.main} elevation={2}>
                <div className={classes.container}>
                  <Typography variant="h5" component="h3">You can only edit (address, customer, vendor, status)</Typography>
                  <pre>
                    <code className="json">
                      <ReactJson
                        src={data.jobById}
                        shouldCollapse={({ name }) => ![false, 'address', 'customer', 'vendor', 'status'].includes(name)}
                        sortKeys
                        onEdit={onEdit}
                        validationMessage="You cannot edit this field"
                        name={false}
                      />
                    </code>
                  </pre>
                </div>
              </Paper>
            );
          }}
        </Query>
      )}
    </div>
  );
}

jobEditor.propTypes = {
  classes: object.isRequired,
  updateJobEditor: func.isRequired,
  setStatus: func.isRequired,
};

const JobEditorWithMutation = compose(
  graphql(UPDATE_JOB_EDITOR, { name: 'updateJobEditor' }),
  graphql(JOB_STATUS, { name: 'setStatus' }),
  withStyles(styles),
)(jobEditor);

export default JobEditorWithMutation;
