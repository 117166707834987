import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { GRAPHQL_URL, TOKEN, GET_AUTH_TOKEN } from '../constants';

const defaultOptions = {
  watchQuery: {
    // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = TOKEN();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${GET_AUTH_TOKEN()}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          searchJobs: {
            keyArgs: false,
            merge: false,
          },
        },
      },
      AggregatedAttributes: {
        merge: true,
      },
      Order: {
        merge: true,
      },
      Customer: {
        merge: true,
      },
      Address: {
        merge: true,
      },
    },
  }),
  defaultOptions: defaultOptions,
});


export default client;
