import React from 'react';
import Moment from 'moment';
import orderBy from 'lodash/orderBy';
import { arrayOf, func, string } from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { noteType } from 'shared/types';
import { isChangeOrderNote, isInStoreRole } from 'shared/utilities';
import styles from './styles';

const useStyles = makeStyles(styles);

function NoteItemContent({ notes, status, source, onCloseNote, onClickAckChange }) {
  const classes = useStyles();

  if (!notes.length) return <Typography className={classes.blankState}>No Notes</Typography>;

  const notesOrderedByDate = orderBy(notes, note => new Date(note.date), ['desc']);

  return notesOrderedByDate.map(({
    id,
    author,
    authorRole,
    content,
    date,
    closedBy,
    closedOn,
  }) => {
    const role = authorRole === 'Cec' ? authorRole.toUpperCase() : authorRole;
    const isRoleInStore = isInStoreRole(authorRole);
    const isChangeOrder = isChangeOrderNote(author, authorRole, source);
    const noteRead = !!(closedBy || closedOn);
    return (
      <div key={id} className={classes.noteContainer}>
        <div className={classes.contentContainer}>
          <Typography variant="h6" className={classes.authorText}>
            {role} ({author})
          </Typography>
          <Typography variant="body1" className={classes.body}>
            {content}
          </Typography>
          {
            !noteRead && isRoleInStore && !isChangeOrder && (
              <div className={classes.disabledButtonWrapper}>
                <Button
                  variant="text"
                  onClick={() => onCloseNote(id)}
                  classes={{ label: classes.buttonLabel }}
                  className={classes.confirmButton}
                  color="primary"
                >
                  <Typography variant="body1" className={classes.confirmButtonText}>
                  Confirm Note Read
                  </Typography>
                </Button>
              </div>
            )
          }
          {
            !noteRead && isChangeOrder && status && (status.toLowerCase() === 'changed') && (
              <div className={classes.disabledButtonWrapper}>
                <Button
                  variant="text"
                  onClick={onClickAckChange}
                  classes={{ label: classes.buttonLabel }}
                  className={classes.confirmButton}
                  color="primary"
                >
                  <Typography variant="body1" className={classes.confirmButtonText}>
                  Acknowledge Change Order
                  </Typography>
                </Button>
              </div>
            )
          }
          {
            noteRead && (
              <Typography variant="caption" className={classes.body}>
                <Check className={classes.check} />
                <span className={classes.noteReadText}>
                  {isChangeOrder ? 'Acknowledged' : 'Note Read'} {(!closedOn ? '' : Moment(closedOn).format('- L'))} - {closedBy}
                </span>
              </Typography>
            )
          }
        </div>
        <div className={classes.dateContainer}>
          <Typography variant="body1">
            {Moment(date).format('MMM DD')}
          </Typography>
        </div>
      </div>
    );
  });
}

NoteItemContent.propTypes = {
  notes: arrayOf(noteType).isRequired,
  status: string.isRequired,
  source: string.isRequired,
  onCloseNote: func,
  onClickAckChange: func,
};

NoteItemContent.defaultProps = {
  onCloseNote: () => {},
  onClickAckChange: () => {},
};

export default NoteItemContent;
