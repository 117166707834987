import React from 'react';
import {
  func,
  node,
} from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

function Dashboard({ renderSidebar, children }) {
  const classes = useStyles();
  const sideBarClasses = `${classes.sidebar} do-not-print`;

  return (
    <div className={classes.container}>
      <Paper className={sideBarClasses} elevation={2}>
        {renderSidebar ? renderSidebar() : null}
      </Paper>
      <div className={classes.mainContent}>
        {children}
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  renderSidebar: func,
  children: node.isRequired,
};

Dashboard.defaultProps = {
  renderSidebar: undefined,
};

export default Dashboard;
