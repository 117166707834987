export default theme => ({
  root: {
    marginTop: theme.spacing(1),
    padding: '5px 16px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
});
