import React from 'react';

import { jobType } from '../../types';
import ServiceTypeIcon from '../ServiceTypeIcon';

const ServiceTypeCell = ({ job = {} }) => (
  <ServiceTypeIcon serviceType={job.serviceType} size="small" />
);

ServiceTypeCell.propTypes = {
  job: jobType.isRequired,
};

export default ServiceTypeCell;
