export default theme => ({
  noteContainer: {
    borderBottom: '1px solid #ccc',
    padding: theme.spacing(2),
    display: 'flex',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  dialogContainer: {
    minWidth: '34rem',
  },
  authorText: {
    fontSize: '18px',
    fontWeight: theme.text.boldWeight,
  },
  blankState: {
    paddingBottom: theme.spacing(1),
  },
  confirmButton: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.link,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  confirmButtonText: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  check: {
    color: theme.palette.primary.green,
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    fontSize: '20px',
  },
  noteReadText: {
    verticalAlign: 'middle',
    color: theme.palette.primary.darkGrey,
    fontSize: '14px',
  },
  body: {
    whiteSpace: 'pre-line',
  },
});
