import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import {
  object,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import WarningIcon from '@material-ui/icons/Warning';
import { jobType } from 'shared/types';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  orderNumber: {
    textDecoration: 'none',
    color: theme.palette.primary.link,
  },
  warning: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(1),
    color: 'orange',
  },
});

const OrderNumberCell = ({ job, classes }) => {
  const orderNumber = get(job, 'order.orderNumber');
  const overSized = !!get(job, 'aggregatedAttributes.oversizedVerticalFeet');
  const jobDetailsUrl = `/job/${job.id}`;

  return (
    <div className={classes.container}>
      <Link
        to={jobDetailsUrl}
        className={classes.orderNumber}
      >
        {orderNumber}
      </Link>
      { overSized && <WarningIcon className={classes.warning} /> }
    </div>
  );
};

OrderNumberCell.propTypes = {
  job: jobType.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(OrderNumberCell);
