import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';
import StaticPdfLinks from './StaticPdfLinks';
import UploadedPdfLinks from './UploadedPdfLinks';

const PdfLinks = ({
  job,
  classes,
}) => (
  <div className={classes.pdfWrapper}>
    <StaticPdfLinks job={job} />
    <UploadedPdfLinks documents={job.documents} />
  </div>
);
PdfLinks.propTypes = {
  job: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(PdfLinks);
