export default theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.red,
  },
  message: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: theme.palette.primary.link,
    textDecoration: 'none',
  },
});
