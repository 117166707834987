/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxesGroup = ({ selectedItems, setSelectedItems, list }) => {
  const handleChange = (event) => {
    setSelectedItems({ ...selectedItems, [event.target.name]: event.target.checked });
  };
  return (
    <FormGroup>
      { list.map(listItem => (
        <FormControlLabel
          key={listItem}
          control={(
            <Checkbox
              checked={selectedItems[listItem]}
              onChange={handleChange}
              name={listItem}
            />
          )}
          label={listItem}
        />
      ))}
    </FormGroup>
  );
};

CheckboxesGroup.propTypes = {
  list: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
};

export default CheckboxesGroup;
