import React from 'react';
import {
  node,
} from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { TOKEN } from '../../constants';

function ProtectedRoute({ children, ...rest }) {
  const isAuthenticated = TOKEN();

  return (
    <Route
      {...rest}
      render={
        () => (isAuthenticated ? children : <Redirect to="/login" />)
      }
    />
  );
}

ProtectedRoute.propTypes = {
  children: node.isRequired,
};

export default ProtectedRoute;
