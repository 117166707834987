import React from 'react';
import {
  string,
  arrayOf,
  shape,
  func,
  node,
  object,
  oneOfType,
} from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontWeight: theme.text.boldWeight,
    display: 'flex',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleWrapper: {
    paddingBottom: theme.spacing(0.5),
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    display: 'inline-block',
  },
  subtitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    paddingBottom: theme.spacing(0.5),
    justifyContent: 'flex-start',
  },
});

const renderActions = (actionsConfig) => {
  if (!actionsConfig.length) return null;

  return actionsConfig.map(({ id, renderAction }) => (
    <div key={id}>
      {renderAction()}
    </div>
  ));
};

const Card = ({ title, actionsConfig = [], children, classes, subtitle }) => (
  <Paper className={classes.card} elevation={2}>
    <div className={classes.header}>
      <Typography
        variant="h5"
        classes={{ root: classes.cardTitle }}
      >
        <span className={classes.titleWrapper}>
          {title}
        </span>
      </Typography>
      <span className={classes.subtitleWrapper}>
        {subtitle}
      </span>
      <div className={classes.actionsContainer}>
        {renderActions(actionsConfig)}
      </div>
    </div>
    {children}
  </Paper>
);

Card.propTypes = {
  title: oneOfType([
    string,
    object,
  ]).isRequired,
  actionsConfig: arrayOf(
    shape({
      id: string.isRequired,
      renderAction: func.isRequired,
    }),
  ),
  children: node.isRequired,
  classes: object.isRequired,
  subtitle: oneOfType([
    string,
    object,
  ]),
};

Card.defaultProps = {
  actionsConfig: [],
  subtitle: null,
};

export default withStyles(styles)(Card);
