export default theme => ({
  calendarWrapper: {
    marginTop: theme.spacing(1),
  },
  calendar: {
    border: theme.border,
    borderRadius: '4px',
  },
  timeslotButton: {
    marginTop: theme.spacing(1),
  },
  selected: {
    border: `2px solid ${theme.palette.primary.link}`,
  },
  title: {
    fontWeight: theme.text.boldWeight,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDiretion: 'column',
  },
  widthContainer: {
    maxWidth: '22rem',
  },
  contentText: {
    fontSize: '0.875rem',
  },
  confirmWarning: {
    marginTop: theme.spacing(2),
    fontSize: '0.875rem',
  },
  cancelButton: {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  circularSpinnerContainer: {
    height: '22rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularSpinnerText: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#000000',
    marginTop: '16px',
  },
  errorMessageContainer: {
    height: '22rem',
    width: '21rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessageText: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '5px',
    textAlign: 'center',
    color: '#000000',
    marginTop: '0.1rem',
  },
  footer: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});
