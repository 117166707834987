import getJobWillCall from './getJobWillCall';

const getJobWillCallMsg = (job) => {
  const willCall = getJobWillCall(job);

  if (willCall.isWillCall) {
    return willCall.deliveryIndicatorValue;
  }

  return undefined;
};

export default getJobWillCallMsg;
