const LOADING_OVERLAY_Z_INDEX = 2;

export default theme => ({
  loadingOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'white',
    zIndex: LOADING_OVERLAY_Z_INDEX,
  },
  bringToOverlay: {
    zIndex: LOADING_OVERLAY_Z_INDEX,
  },
  loadingText: {
    marginTop: 15,
  },
  titleText: {
    fontWeight: theme.text.boldWeight,
    color: 'black',
  },
  buttonTextTransform: {
    textTransform: 'capitalize',
  },
});
