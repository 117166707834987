export default theme => ({
  headerText: {
    fontWeight: theme.text.boldWeight,
    fontSize: theme.typography.pxToRem(15),
  },
  folder: {
    margin: '10px',
  },
  files: {
    padding: '10px 0px',
  },
  rows: {
    flexWrap: 'wrap',
    flex: 1,
  },
  downloadPDFLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.link,
    marginRight: '10px',
    fontWeight: 'bold',
  },
  downloadPDFText: {
    color: theme.palette.primary.link,
    fontSize: '16px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});
