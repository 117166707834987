export default theme => ({
  measurementsWrapper: {
    display: 'flex',
    flex: 5,
    justifyContent: 'space-around',
  },
  container: {
    height: '35px',
    padding: '5px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  },
  border: {
    borderTop: theme.border,
  },
  circleWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 3,
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    backgroundColor: '#F96302',
    margin: '2px',
  },
  circleText: {
    textAlign: 'center',
    color: '#ffffff',
  },
  buttonWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  redFlag: {
    borderLeft: '4px solid rgb(214, 66, 47)',
    marginLeft: 0,
  },
  yellowFlag: {
    borderLeft: '4px solid #FFEB3B',
    marginLeft: 0,
  },
});
