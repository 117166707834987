import formatDateOffsetInMonths from '../../shared/utilities/formatDateOffset';
import { SEARCH_MONTHS } from '../../constants';

export const InitialFromDate = formatDateOffsetInMonths(SEARCH_MONTHS * -1);
export const InitialToDate = new Date().toISOString().substr(0, 10);

export const InitialLocations = ['All', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export const InitialStatuses = {
  All: 'all',
  Completed: 'completed',
  Cancelled: 'cancelled',
  'Finalized In App': 'finalized-in-app',
  'In Progress': 'in-progress',
  'Pending Products': 'pending-products',
  'Pending Schedule': 'pending-schedule',
  'Pending Assignment': 'pending-assignment',
  'Pending Change Order Payment': 'pending-change-order-payment',
  Scheduled: 'scheduled',
};

export const InitialServiceTypes = { INSTALLATION: false, MEASUREMENT: false };

export const InitialSites = { THD: false, BDC: false };
