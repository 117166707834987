import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';

const PdfLink = ({
  label,
  classes,
  uri,
  filename,
}) => (
  <a
    key={label}
    className={classes.pdfLink}
    href={uri}
    download={filename}
  >
    {label}
    <DownloadIcon className={classes.pdfIcon} />
  </a>
);
PdfLink.propTypes = {
  label: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  filename: PropTypes.string,
  classes: PropTypes.object.isRequired,
};
PdfLink.defaultProps = {
  filename: '',
};
export default withStyles(styles)(PdfLink);
