import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { string, func, arrayOf } from 'prop-types';

function CreatingApiKeyDialog({ email, mvNumbers, handleChange, cancelToggle, createApiKey }) {
  return (
    <>
      <DialogTitle>Creating API Key</DialogTitle>
      <DialogContent>
        <TextField
          required
          id="standard-name"
          label="email"
          value={email}
          onChange={handleChange('email')}
          margin="normal"
          fullWidth
        />
        <DialogContentText>
          Service Provider Email
        </DialogContentText>
        <TextField
          required
          id="standard-mv"
          label="MvNumbers"
          value={mvNumbers}
          onChange={handleChange('mvNumbers')}
          margin="normal"
          fullWidth
        />
        <DialogContentText>
          sample: 55555,555444,334332
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={cancelToggle} color="primary">
            Cancel
          </Button>
          <Button onClick={createApiKey} variant="contained" disabled={!email || !mvNumbers.length} color="primary">
            create key
          </Button>
        </>
      </DialogActions>
    </>
  );
}

CreatingApiKeyDialog.propTypes = {
  email: string,
  mvNumbers: arrayOf(string),
  handleChange: func.isRequired,
  cancelToggle: func.isRequired,
  createApiKey: func.isRequired,
};

CreatingApiKeyDialog.defaultProps = {
  email: '',
  mvNumbers: [],
};

export default CreatingApiKeyDialog;
