export default theme => ({
  filtersContainer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(6),
    },
  },
  filterSection: {
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    height: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
