/* eslint-disable no-param-reassign */
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

export default function buildItemsTree(items) {
  const sortedItems = sortBy(items, item => item.label);

  return sortedItems.reduce((tree, item) => {
    const { parentId, id } = item;

    if (parentId) {
      const parent = tree[parentId] || (tree[parentId] = { children: [] });
      parent.children.push(item);
    }

    tree[id] = {
      ...item,
      children: get(tree[id], 'children', []),
    };

    return tree;
  }, {});
}
