export default function mutationArgWrapper(name, func) {
  const inputName = `${name}Input`;

  return args => func({
    variables: {
      [inputName]: {
        ...args,
      },
    },
  });
}
