import React from 'react';
import { arrayOf, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SelectedChoice from '../../../../shared/components/SelectedChoice';
import styles from './styles';

const useStyles = makeStyles(styles);

function AssignedTechnicians({ className, technicians }) {
  const classes = useStyles();

  if (technicians.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Typography className={classes.confirmedTechniciansText}>Confirmed Technicians</Typography>
      <div className={classes.selectedChoicesContainer}>
        {technicians.map(value => (
          <SelectedChoice
            key={value}
            className={classes.selectedChoice}
            borderClassName={classes.selectedChoiceBorder}
            label={value}
          />
        ))
          }
      </div>
    </div>
  );
}

AssignedTechnicians.propTypes = {
  className: string,
  technicians: arrayOf(string).isRequired,
};

AssignedTechnicians.defaultProps = {
  className: null,
};

export default AssignedTechnicians;
