import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import {
  object,
  bool,
} from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import { jobType } from 'shared/types';

const styles = theme => ({
  bold: {
    fontWeight: theme.text.boldWeight,
  },
});

function DateCell({ job, isBold, classes }) {
  const date = get(job, 'order.date');

  const dateStyles = classNames(
    { [classes.bold]: isBold },
  );

  return <div className={dateStyles}>{moment(date).format('MMM Do')}</div>;
}

DateCell.propTypes = {
  job: jobType.isRequired,
  classes: object.isRequired,
  isBold: bool,
};

DateCell.defaultProps = {
  isBold: false,
};

export default withStyles(styles)(DateCell);
