export default theme => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    marginTop: theme.spacing(3),
  },
  tableContainer: {
    maxHeight: '72vh',
    '@media print': {
      maxHeight: '100%',
    },
  },
  loadingOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: 2,
  },
  // Override MUI stick header color
  '@global': {
    '.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.background.paper,
      // Allow sticky header to be BEHIND loading overlay
      zIndex: 1,
    },
  },
});
