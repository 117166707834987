import React, { Fragment } from 'react';
import compose from 'lodash/flowRight';
import { useHistory } from 'react-router-dom';
import {
  object,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IS_SUPER_ADMIN, IS_MANDI_DEV } from '../../constants';

import styles from './styles';

const navConfig = [
  {
    route: '/',
    displayName: 'Ready To Schedule',
  },
  {
    route: '/unassigned-jobs',
    displayName: 'Ready To Assign',
  },
  {
    route: '/installer-pickup',
    displayName: 'Installer Pickup',
  },
  {
    route: '/changed-jobs',
    displayName: 'Changed Jobs',
  },
  {
    route: '/all-jobs',
    displayName: 'Jobs',
  },
  {
    route: '/unread-note-jobs',
    displayName: 'Unread Notes',
  },
  {
    route: '/helpful-links',
    displayName: 'Helpful Links',
  },
];

if (IS_SUPER_ADMIN()) {
  navConfig.push({
    route: '/settings',
    displayName: 'Settings',
  });
}

if (IS_MANDI_DEV()) {
  navConfig.push({
    route: '/job-editor',
    displayName: 'Job Editor',
  });
}


function renderMenuItems(routeTo) {
  return navConfig.map(({ route, displayName }) => {
    const routeToRoute = () => routeTo(route);

    return <MenuItem key={route} onClick={routeToRoute}>{displayName}</MenuItem>;
  });
}

function renderMenu(anchorEl, closeMenu, routeTo) {
  return (
    <Menu
      id="nav-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
    >
      {renderMenuItems(routeTo)}
    </Menu>
  );
}

function NavMenu({ classes }) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const routeTo = (route) => {
    closeMenu();
    history.push(route);
  };

  return (
    <Fragment>
      <IconButton
        id="nav-menu-button"
        className={classes.menuButton}
        color="inherit"
        aria-label="Open nav menu"
        onClick={openMenu}
      >
        <MenuIcon />
      </IconButton>
      {renderMenu(anchorEl, closeMenu, routeTo)}
    </Fragment>
  );
}

NavMenu.propTypes = {
  classes: object.isRequired,
};

const EnhancedNavMenu = compose(withStyles(styles))(NavMenu);

export default EnhancedNavMenu;
