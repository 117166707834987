import { chain } from 'lodash';

const getJobWillCall = (job) => {
  const { items } = job;
  const willCallKey = 'WillCallType';
  const willCallValue = 'I';
  const deliveryIndicatorKey = 'DeliveryIndicator';
  const willCallItemQuery = {
    productInformation: {
      configurationDetails: [
        {
          displayName: willCallKey,
          choices: [willCallValue],
        },
      ],
    },
  };

  const willCallConfigDetails = chain(items)
    .find(willCallItemQuery)
    .get('productInformation.configurationDetails');

  const isWillCall = !willCallConfigDetails.isNil().value();

  let deliveryIndicatorValue = '';
  if (isWillCall) {
    deliveryIndicatorValue = willCallConfigDetails
      .find(detail => detail.displayName === deliveryIndicatorKey)
      .get('choices[0]')
      .value();

    if (!deliveryIndicatorValue) {
      deliveryIndicatorValue =
        'As the installer, you are responsible for picking up this merchandise with Installer Pickup and Delivery Charge: $30.00';
    }
  }

  return {
    isWillCall,
    deliveryIndicatorValue,
  };
};

export default getJobWillCall;
