export default [
  {
    title: 'Installation',
    folders: [
      {
        title: 'IOS',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FIOS%2F2.5-App-iOS-Final-v1.docx?alt=media&token=6004245b-873e-4029-8dca-c79099023e57',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FIOS%2F2.5-App-iOS-Final-v1.pptx?alt=media&token=beb88cfc-ac0f-406a-8ccb-ffa3a3abfea0',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FIOS%2F2.5-App-iOS-Final-v1.pdf?alt=media&token=f23cee47-f33e-492a-8dd9-8c22cecef149',
            title: 'PDF',
          },
        ],
      },
      {
        title: 'Android',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FAndroid%2F2.5-App-Android-Final-v1.docx?alt=media&token=3fe6515b-ecaf-479b-ade1-7684e31591b7',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FAndroid%2F2.5-App-Android-Final-v1.pptx?alt=media&token=aae34f50-94cd-4865-bb01-4a78cd259712',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FInstallation%2FAndroid%2F2.5-App-Android-Final-v1.pdf?alt=media&token=f8300ad0-53b2-45f5-887d-ad42d49f8404',
            title: 'PDF',
          },
        ],
      },
    ],
  },
  {
    title: 'Job Scheduling & Administration',
    files: [
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FJob%20Scheduling%20%26%20Administration%2F2.5%20Service%20Provider%20Admin%20Revisions%20v3a.docx?alt=media&token=2f7d335b-62e9-4047-8ce3-cda6f732b308',
        title: 'Word Doc',
      },
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FJob%20Scheduling%20%26%20Administration%2F2.5%20Service%20Provider%20Admin%20Revisions%20v3a.pptx?alt=media&token=e069421f-074f-4f4a-b223-b17990559172',
        title: 'PowerPoint',
      },
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FJob%20Scheduling%20%26%20Administration%2F2.5%20Service%20Provider%20Admin%20Revisions%20v3a.pdf?alt=media&token=229a97b2-8648-4ba5-b44d-3280e834ba53',
        title: 'PDF',
      },
    ],
  },
  {
    title: 'Service Provider Application',
    folders: [
      {
        title: 'App Overview',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5%20App-Overview%20Rev1a.docx?alt=media&token=8ddbc9f4-d400-4093-ab70-70261a6f78f4',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5%20App-Overview%20Rev1a.pptx?alt=media&token=53f3301b-7b61-40ff-8053-cab04ffb817c',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5%20App-Overview%20Rev1a.pdf?alt=media&token=bda9ee58-0468-4f45-abf9-f4af56cdf7b5',
            title: 'PDF',
          },
        ],
      },
      {
        title: 'Measure',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Measure-Rev2.docx?alt=media&token=c4d8118b-c58e-446d-9d37-c91fe94dd492',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Measure-Rev2.pptx?alt=media&token=1215afb5-419a-4947-8485-6437dd3a6178',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Measure-Rev2.pdf?alt=media&token=6e5d176c-14a7-4c2e-ad2a-0fd5adf184f0',
            title: 'PDF',
          },
        ],
      },
      {
        title: 'Install',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Install-Rev2.docx?alt=media&token=dda06628-5ca0-4cc2-bd6f-24036b776b2c',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Install-Rev2.pptx?alt=media&token=9d8e720e-e183-4fb6-9887-58f733cd315f',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-Install-Rev2.pdf?alt=media&token=40024a6b-636c-4002-bac5-ecb06f98bec3',
            title: 'PDF',
          },
        ],
      },
      {
        title: 'ReInstall',
        files: [
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-ReInstall-Rev1a.docx?alt=media&token=9333ebc1-7931-4137-8db7-50964c5a4ee8',
            title: 'Word Doc',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5%20Reinstall%20Rev1a.pptx?alt=media&token=7760dff5-bbda-44be-9724-fbacd342a6fd',
            title: 'PowerPoint',
          },
          {
            url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FService%20Provider%20Application%2F2.5-ReInstall-Rev1a.pdf?alt=media&token=6ace4528-1cce-43ea-8a2a-b3e1b516f85d',
            title: 'PDF',
          },
        ],
      },
    ],
  },
  {
    title: 'SystemRequirements',
    files: [
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FSystem%20Requirements%2FSYSTEM%20REQUIREMENTS.pdf?alt=media&token=df49fef2-db00-49f4-aeb9-7c4856ebcbfa',
        title: 'PDF',
      },
    ],
  },
  {
    title: 'Videos',
    files: [
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FVideos%2FMandi-SP-Final-Mobile.mp4?alt=media&token=daff6ccd-bbd7-46ba-9664-88d3fb0a9000',
        title: '2.5 New Features',
      },
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/gcc-prod-653e7/o/Training%20Assets%2FVideos%2FMandi-SP-Change%20Order%201-Final.mp4?alt=media&token=6fe512f1-197c-417c-9278-6b068d566610',
        title: 'Change Order',
      },
    ],
  },
];
