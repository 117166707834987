import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const SelectedChoice = (props) => {
  const {
    label,
    icon,
    className,
    borderClassName,
    ...restProps
  } = props;

  const classes = useStyles();

  return (
    <div {...restProps} className={classNames(className, classes.root, borderClassName)}>
      {icon}
      {label}
    </div>
  );
};

SelectedChoice.defaultProps = {
  icon: null,
  className: null,
};

SelectedChoice.propTypes = {
  label: string.isRequired,
  icon: node,
  className: string,
  borderClassName: string.isRequired,
};

export default SelectedChoice;
