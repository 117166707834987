export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainToolBar: {
    minHeight: '48px',
    backgroundColor: '#C14C09',
  },
  titleBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '64px',
    color: theme.palette.primary.contrastText,
  },
  logo: {
    backgroundColor: theme.palette.primary.main,
    width: '32px',
    height: '32px',
    marginRight: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
  },
  user: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
    textAlign: 'right',
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
  filterMessage: {
    fontSize: theme.spacing(1.6),
    marginLeft: theme.spacing(1),
  },
  notificationBadge: {
    '& .MuiBadge-badge': {
      marginRight: 3,
    },
  },
});
