import React from 'react';
import {
  string,
  object,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    color: 'white',
    textAlign: 'center',
    fontWeight: theme.text.boldWeight,
  },
  small: {
    maxWidth: '22px',
    fontSize: '16px',
    padding: '2px 2px 0 2px;',
    lineHeight: '20px',
  },
  large: {
    maxWidth: '35px',
    fontSize: '22px',
    lineHeight: '24px',
    padding: '3px 6px 3px 6px',
  },
  measurement: {
    backgroundColor: '#5e2ca5',
  },
  installation: {
    backgroundColor: '#00ae8e',
  },
  reinstall: {
    backgroundColor: theme.palette.primary.red,
  },
});

const serviceTypeToText = {
  measurement: 'M',
  installation: 'I',
  reinstall: 'R',
};

const ServiceTypeIcon = ({ serviceType, size, classes }) => {
  const containerStyles = `${classes.container} ${classes[serviceType]} ${classes[size]}`;
  const text = serviceTypeToText[serviceType];

  return (
    <div className={containerStyles}>
      {text}
    </div>
  );
};

ServiceTypeIcon.propTypes = {
  serviceType: string.isRequired,
  size: string.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(ServiceTypeIcon);
