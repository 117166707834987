import React from 'react';
import get from 'lodash/get';
import {
  object,
  arrayOf,
} from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

import { displayAttributeType, productInformationType } from 'shared/types';
import styles from './styles';

const detailsConfig = [
  {
    label: 'Flag Note',
    path: 'flagNote.content',
  },
  {
    label: 'Window Note',
    path: 'note.content',
  },
  {
    label: 'Mount',
    path: 'attributes.mountType',
  },
  {
    label: 'Category',
    path: 'attributes.category',
  },
  {
    label: 'Photos',
    path: 'photos',
  },
];

const renderWindowDetail = (label, value, key, classes) => {
  if (!label || !value) return null;

  const displayValue = value === 'True' ? 'Yes' : value;

  if (Array.isArray(value) && label === 'Photos') {
    const images = value.map(({ url }) => ({ original: url, thumbnail: url }));

    if (images.length === 0) return null;

    return (
      <div key={key} className={classes.photosAttributeWrapper}>
        <Typography variant="caption" className={classes.photoLabel}>
          {label}:
        </Typography>
        {(images.length > 0) && (
          <div style={{ width: 300 }}>
            <ImageGallery items={images} showFullscreenButton useBrowserFullscreen={false} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div key={key} className={classes.attributeWrapper}>
      <Typography variant="caption" className={classes.label}>
        {label}:
      </Typography>
      <Typography variant="caption" className={classes.value}>
        {displayValue}
      </Typography>
    </div>
  );
};

const renderProductName = (productInformation, classes) => {
  const productName = get(productInformation, 'productName');

  if (!productName) return null;

  return renderWindowDetail(
    'Product Name',
    productName,
    'productName',
    classes,
  );
};


const renderDetails = (windowInfo, classes) => detailsConfig.map(({ label, path }) => {
  const value = get(windowInfo, path);

  return renderWindowDetail(label, value, label, classes);
});

const attributesToOmit = [
  'width',
  'height',
  'depth',
  'category',
  'mountType',
  'mount',
];

const renderDisplayAttributes = (displayAttributes, classes) => displayAttributes
  .filter(({ attributeKey, value }) => {
    const notOmitted = !attributesToOmit.includes(attributeKey);
    const truthy = value !== 'False';

    return notOmitted && truthy;
  })
  .map(({ attributeKey, displayName, value }) => (
    renderWindowDetail(displayName, value, attributeKey, classes)
  ));

const renderProductInformation = (productInformation, classes) => {
  if (!productInformation) return null;

  const { configurationDetails = [] } = productInformation;

  return configurationDetails
    .filter(({ displayName }) => !attributesToOmit.includes(displayName.toLowerCase()))
    .map(({ displayName, choices = [] }) => {
      const value = choices[0];

      return renderWindowDetail(displayName, value, displayName, classes);
    });
};

const WindowSummaryDetails = ({
  classes,
  displayAttributes,
  productInformation = {},
  ...windowInfo
}) => (
  <div className={classes.detailsContainer}>
    {renderDetails(windowInfo, classes)}
    {renderProductName(productInformation, classes)}
    {renderDisplayAttributes(displayAttributes, classes)}
    {renderProductInformation(productInformation, classes)}
  </div>
);

WindowSummaryDetails.propTypes = {
  classes: object.isRequired,
  displayAttributes: arrayOf(displayAttributeType),
  productInformation: productInformationType,
};

WindowSummaryDetails.defaultProps = {
  displayAttributes: [],
  productInformation: {},
};

export default withStyles(styles)(WindowSummaryDetails);
