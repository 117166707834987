import React from 'react';

import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import PdfLink from './PdfLink';

const getUploadedLabel = (doc) => {
  const label = `Download ${doc.filename}`;

  if (doc.formNumber) {
    return `${label} (Form ${doc.formNumber})`;
  }
  return label;
};

const UploadedPdfLinks = ({ documents }) => flow(
  map(doc => ({
    ...doc,
    label: getUploadedLabel(doc),
  })),
  sortBy('dateUploaded'),
  map(doc => <PdfLink {...doc} />),
)(documents);
export default UploadedPdfLinks;
